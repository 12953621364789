<mat-expansion-panel #matExpansionPanel>
  <mat-expansion-panel-header>
    <mat-panel-title data-cy="detail-header">Carbon Offset Site Information</mat-panel-title>
  </mat-expansion-panel-header>

  <mat-divider></mat-divider>

  <div class="section-content" *ngIf="site">
    <fc-site-detail-overview
      [readOnly]="!isAdmin && !isSuperAdmin"
      [isSuperAdmin]="isSuperAdmin"></fc-site-detail-overview>
    <div *ngFor="let parcel of parcelData; index as i">
      <mat-divider></mat-divider>
      <div class="parcel-header">
        Parcel {{ i + 1 }}
        <fa-icon
          (click)="editParcel(parcel)"
          *ngIf="isAdmin"
          [icon]="['far', 'pen-to-square']"
          class="blue edit-parcel"></fa-icon>
      </div>
      <table
        aria-description="Parcel Information"
        role="presentation"
        [ngStyle]="{ 'margin-bottom': '20px' }"
        class="info-table">
        <tr>
          <td class="wide">
            <span class="label">Parcel Number:</span>
            <span class="field">{{ parcel.parcelNumber }}</span>
          </td>
        </tr>
        <tr>
          <td class="wide">
            <span class="label">Owner Name:</span>
            <span class="field">{{ parcel.owner }}</span>
          </td>
        </tr>
        <tr>
          <td class="wide">
            <span class="label">Address:</span>
            <span class="field">{{ parcel.address }}</span>
          </td>
        </tr>
      </table>
    </div>

    <mat-divider></mat-divider>

    <div class="section-header spaced">Eligibility Questions</div>

    <div [ngClass]="getQuestionClass('hasRestrictions')">
      <fa-icon
        *ngIf="flagQuestion('hasRestrictions') && isAdmin"
        [icon]="['far', 'flag']"
        class="red flag-image"></fa-icon>
      Is this acreage encumbered by a deed, mortgage or legal restriction that impacts the timber on this acreage?
      <span class="answer">
        {{ getBooleanString(site.hasRestrictions) }}
      </span>
    </div>

    <div [ngClass]="getQuestionClass('hasLeakage')">
      <fa-icon *ngIf="flagQuestion('hasLeakage') && isAdmin" [icon]="['far', 'flag']" class="red flag-image"></fa-icon>
      <span>
        Do you own forests outside of the selected stands in your carbon offset site, and if so, do you plan to conduct
        timber harvests in those areas that total 40 acres or more in a single stand or group of stands?
        <br />
        <br />
        <span *ngIf="site && site.periodicReports[0] && site.periodicReports[0].leakageInfo">
          <strong>Details:</strong>
          {{ site.periodicReports[0].leakageInfo }}
        </span>
      </span>
      <span class="answer">
        {{ getBooleanString(site && site.periodicReports[0] && site.periodicReports[0].activityLeakage) }}
      </span>
    </div>

    <div [ngClass]="getQuestionClass('programs')">
      <fa-icon *ngIf="flagQuestion('programs') && isAdmin" [icon]="['far', 'flag']" class="red flag-image"></fa-icon>
      Is this acreage included in any other program(s)?
      <span class="answer">
        {{ getBooleanString(site.programs && site.programs.length > 0) }}
      </span>
    </div>

    <div *ngIf="programs.length > 0" class="alert-container">
      <div class="indented section-header">Other Carbon Programs</div>

      <ul class="program-tiles-container">
        <li *ngFor="let program of programs" class="program-tile">
          <div *ngIf="isAdmin && program.restrictionLevel === -1">
            <fa-icon [icon]="['fas', 'question']" class="blue"></fa-icon>
          </div>
          <div *ngIf="isAdmin && program.restrictionLevel === 0">
            <fa-icon [icon]="['fas', 'circle']" class="green"></fa-icon>
          </div>
          <div *ngIf="isAdmin && program.restrictionLevel === 1">
            <fa-icon [icon]="['fas', 'triangle']" class="yellow"></fa-icon>
          </div>
          <div *ngIf="isAdmin && program.restrictionLevel === 2">
            <fa-icon [icon]="['fas', 'octagon']" class="red"></fa-icon>
          </div>
          {{ program.name }}
          <span *ngIf="!program.isActive" class="inactive">(Inactive)</span>
        </li>
      </ul>
    </div>
    <mat-divider class="spaced"></mat-divider>

    <button (click)="doOpenSite()" class="view-map-link" color="primary" mat-stroked-button>View Map</button>
  </div>
</mat-expansion-panel>
