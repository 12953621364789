<div class="background-container">
  <div class="outer-container">
    @if (site) {
      <div class="page-heading-container">
        <div class="page-name-row">
          {{ site.name }}
        </div>
        @if (isAdmin) {
          <div class="page-status-row">
            <span>Status:</span>
            {{ site.status }}
          </div>
        }
        <mat-divider></mat-divider>
        @if (!readOnly) {
          <div class="page-description">
            Please take a moment to fill out the questions to keep us informed on the status of your Carbon Offset Site.
            Some answers may require some additional description.
          </div>
        }
      </div>
      <div class="inner-container">
        <div class="site-name">
          Site {{ reportType }} Report
          @if (beginningDate && endDate) {
            <span>
              {{ beginningDate | date: 'shortDate' }} -
              {{ endDate | date: 'shortDate' }}
            </span>
          }
          @if (report?.originalReportId && isAdmin) {
            <a
              [routerLink]="['/admin/site', report.siteId, getReportTypeUrl() + '-report', report.originalReportId]"
              target="_blank">
              View Original
            </a>
          }
        </div>
        <mat-divider>&nbsp;</mat-divider>
        <div class="site-overview">
          <fc-site-detail-overview [readOnly]="true"></fc-site-detail-overview>
        </div>
        <mat-divider>&nbsp;</mat-divider>
        <div class="full-form">
          <fc-report-form
            [activeSite]="site"
            [eligibilityForm]="reportForm"
            [reportConfig]="reportConfig"
            [reportResponse]="report"
            [currentUser]="currentUser"></fc-report-form>
        </div>
        @if (report === null) {
          <div class="buttons">
            <button
              (click)="submitReport()"
              [disabled]="reportForm.invalid"
              color="primary"
              data-cy="submit-report-button"
              mat-raised-button>
              Submit Report
            </button>
          </div>
        }
      </div>
    }
  </div>
  <input #documentUpload accept="*" id="documentUpload" name="documentUpload" style="display: none" type="file" />
</div>
