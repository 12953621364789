<div class="section-content">
  @if (!readOnly && isSiteSubmittedNotActive()) {
    <div class="flag-section">
      @if (hasOverlaps()) {
        <div class="parcel-overlap">
          <fa-icon [icon]="['far', 'flag']" class="red"></fa-icon>
          Potential overlap with other sites
          <a (click)="navigateByID(id)" *ngFor="let id of site.siteOverlaps">({{ id }})</a>
        </div>
      }
      @if (hasParcelOverlap()) {
        <div class="parcel-overlap w-100-1">
          <fa-icon [icon]="['far', 'flag']" class="red"></fa-icon>
          Potential overlap with other parcels! Click
          <a routerLink="/admin/{{ site.id }}/locate-property/select-parcels">here</a>
          to view parcels.
        </div>
      }
      @if (publicOverlap > 0) {
        <div class="red-flag w-100-1">
          <fa-icon [icon]="['far', 'flag']" class="red"></fa-icon>
          Site overlap with red flag layer by
          {{ publicOverlap | number: '1.0-4' }} acres
        </div>
      }
      @if (hasOutdatedParcels) {
        <div class="red-flag w-100-1">
          <fa-icon [icon]="['far', 'flag']" class="red"></fa-icon>
          Some parcels are outdated. Click
          <a routerLink="/admin/{{ site.id }}/locate-property/select-parcels">here</a>
          to view parcels.
        </div>
      }
      @if (hasStandOverage()) {
        <div class="area-outside w-100-1">
          <fa-icon [icon]="['far', 'flag']" class="red"></fa-icon>
          Site has {{ site.standOverage | number: '1.0-4' }} acres outside of selected parcels
        </div>
      }
      @if (hasValuationIssue()) {
        <div class="valuation-check w-100-1">
          <fa-icon [icon]="['far', 'flag']" class="red"></fa-icon>
          Site has a different valuation ({{ site.valuation.split(',')[0] | number: '1.0-4' }}) at submission than
          auto-calculated ({{ site.valuationCheck | number: '1.0-4' }})
        </div>
      }
    </div>
  }
  <div class="section-header w-100-1">Site Overview</div>
  <div class="overview-data w-100-1">
    <div class="section-container">
      <div class="info-container w-50-2">
        <span class="label">Site ID:</span>
        <span class="field">{{ site.id }}</span>
      </div>

      <div class="info-container w-50-2">
        @if (!readOnly) {
          <span class="label">Assigned Admin:</span>
          <mat-form-field class="field admin-select">
            <mat-select [(ngModel)]="assignedAdminId" (selectionChange)="assignedAdminChange($event)">
              <mat-option [value]="-1">Unassigned</mat-option>
              <mat-option *ngFor="let admin of admins" [value]="admin.id">
                {{ admin.getDisplayName() }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        }
      </div>

      <div class="info-container w-50-2">
        <span class="label">Site Name:</span>
        <span class="field">
          {{ site.name }}
          @if (!readOnly) {
            <fa-icon
              #tooltip="matTooltip"
              (click)="changeSiteName()"
              [icon]="['far', 'pen-to-square']"
              class="blue"
              matTooltip="Change Site Name"
              matTooltipClass="fc-tooltip"
              matTooltipPosition="right"></fa-icon>
          }
        </span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Cohort:</span>
        <span class="field">
          {{ site && site.cohort ? site.cohort.name : 'Not Selected' }}
        </span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Submitted Date:</span>
        <span class="field">
          {{ site.submittedDate | date: 'shortDate' }}
        </span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Parcel Acres:</span>
        <span class="field">
          {{
            parcelArea.toLocaleString(undefined, {
              maximumFractionDigits: 2
            })
          }}
        </span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Applicant:</span>
        <span class="field">
          <a [routerLink]="['/admin/profile', site.account.user.id]" target="_blank" rel="noopener noreferrer">
            {{ userName }}
          </a>
          @if (isSuperAdmin) {
            <fa-icon
              #tooltip="matTooltip"
              (click)="changeOwner()"
              [icon]="['far', 'pen-to-square']"
              class="blue"
              matTooltip="Change Owner"
              matTooltipClass="fc-tooltip"
              matTooltipPosition="right"></fa-icon>
          }
        </span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Eligible Forested Acres:</span>
        <span class="field">
          {{
            site?.potentialArea?.toLocaleString(undefined, {
              maximumFractionDigits: 2
            })
          }}
        </span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Average Annual Revenue:</span>
        <span class="field">
          {{ projectedNext12MonthRevenue }}
          <fa-icon
            #tooltip="matTooltip"
            (click)="tooltip.toggle()"
            [icon]="['far', 'circle-info']"
            class="blue"
            matTooltip="Estimated annual revenue is the base payout for the next year of payments. This value can change based on your responses to periodic reporting or on market performance."
            matTooltipClass="fc-tooltip"
            matTooltipPosition="right"></fa-icon>
        </span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Forested Acres:</span>
        <span class="field">{{ acres }}</span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Delegate:</span>
        <span class="field">{{ delegateName }}</span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Names on Deed:</span>
        <span class="field">{{ site.namesOnDeed }}</span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Forestry Firm:</span>
        <span class="field">{{ site.forestryFirm }}</span>
      </div>

      <div class="info-container w-50-2">
        <span class="label">Mortgage Info:</span>
        <span class="field">{{ site.mortgageInformation }}</span>
      </div>
    </div>
  </div>
</div>
